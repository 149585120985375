import React from "react"
import styled from "styled-components"
import AspectFittedImage from "./aspectFittedImage.js"
import { Link } from "gatsby";
import hexRgb from "hex-rgb"

const TilePlaceholder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const Container = styled(Link)`
    position: relative;
    width: 100%;
    height: 100%;
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: ${props => props.theme.color};

    user-select: none;
    transition: .3s ease;
    opacity: 0;

    &:hover {
        opacity: 1;
    }

    @media (hover: none), (hover:on-demand) {
        &:hover {
            opacity: 0;
        }
    }
}
`

class WorkTile extends React.PureComponent {

    render() {
        const work = this.props.work
        const tileImage = work.data.featured_image

        let contentComponent
        if (tileImage == null || tileImage.localFile == null) {
            contentComponent = <TilePlaceholder />
        } else {
            const tileImageData = tileImage.localFile.childImageSharp.gatsbyImageData
            contentComponent = <AspectFittedImage image={tileImageData} />
        }

        const primaryRGB = hexRgb("#292929")
        const tileOpacity = 10 / 100
        const overlayTheme = {
            color: `rgba(${primaryRGB.red}, ${primaryRGB.green}, ${primaryRGB.blue}, ${tileOpacity})`
        }

        return (
            <Container to={this.props.work.url}>
                {contentComponent}
                <Overlay theme={overlayTheme} />
            </Container>
        )
    }
}

export default WorkTile