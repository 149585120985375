import React from "react"
import { graphql } from "gatsby";
import styled from "styled-components"
import WorkTile from "../components/workTile"
import SEO from "../components/SEO/SEO"

const Container = styled.div``

const WorkList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 16px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

const WorkTileWrapper = styled.div`
  background: #f5f5f5;
  display: flex;
  height: 100%;

  ::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(3/4 * 100%);
  }
`

const Index = ({ data: { prismicWork } }) => (
  <Container>
    <SEO />
    <WorkList>
      {prismicWork.data.works.map((item, index) => {
        const work = {
          url: item.work_detail.url,
          data: item.work_detail.document.data
        }
        return (
          <WorkTileWrapper key={index} >
            <WorkTile work={work} />
          </WorkTileWrapper>
        )
      })}
    </WorkList>
  </Container>
)

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    prismicWork {
      data {
        works {
          work_detail {
            url
            document {
              __typename
              ...WorkdetailFragment
            }
          }
        }
      }
    }
  }

  fragment WorkdetailFragment on PrismicWorkdetail {
    data {
      title {
        text
      }
      featured_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 648
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`